import React from "react"
import { ColorPalette } from "../Theme"

/** const */
const HeroLifestyleSection: React.FC = () => {
  return (
    <div>
      <img
        src="/lifestyle/norhart-lifestyle.png"
        alt="Norhart Lifestyle Living Apartments"
        loading="eager"
        className="img-fluid w-100"
      />
    </div>
  )
}

/** export */
export default HeroLifestyleSection
